import { default as index3gbEZUuvMsMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/browse/[category]/index.vue?macro=true";
import { default as indexZuGdDRCvQEMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/browse/components/index.ts?macro=true";
import { default as PageFiltersMdutqLJVTfMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/browse/components/PageFilters.vue?macro=true";
import { default as SectionCategoriesrwpceHirqyMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/browse/components/SectionCategories.vue?macro=true";
import { default as SectionCollectionListLZmvA8sQ6rMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/browse/components/SectionCollectionList.vue?macro=true";
import { default as SectionFiltershjA2CvFoyFMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/browse/components/SectionFilters.vue?macro=true";
import { default as SectionGenres0U8I1LMLRGMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/browse/components/SectionGenres.vue?macro=true";
import { default as SectionMoviesIKLNKQ33sEMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/browse/components/SectionMovies.vue?macro=true";
import { default as SectionPopularriZgLx3sSPMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/browse/components/SectionPopular.vue?macro=true";
import { default as SectionSeriespxnnQwJiRpMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/browse/components/SectionSeries.vue?macro=true";
import { default as SkeletonFiltersP60AKXprAEMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/browse/components/SkeletonFilters.vue?macro=true";
import { default as SkeletonGenresFfoRPylswkMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/browse/components/SkeletonGenres.vue?macro=true";
import { default as _91slug_93US7QH60Q8bMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/browse/genre/[slug].vue?macro=true";
import { default as index5DWPToKHHcMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/browse/index.vue?macro=true";
import { default as _91slug_93p37JqAy1IgMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/collections/[slug].vue?macro=true";
import { default as indexJ6nEtSuCT0Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/collections/index.vue?macro=true";
import { default as copyright8x1H9TEHIbMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/copyright.vue?macro=true";
import { default as _91id_93QLC8VWtLdaMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/crew/[id].vue?macro=true";
import { default as index9aeStSzpK4Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/faq/index.vue?macro=true";
import { default as alltrgjmUV4SFMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/favorites/all.vue?macro=true";
import { default as SectionBoughtt2Ng7RBtxQMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/favorites/components/SectionBought.vue?macro=true";
import { default as SectionContinueJFiH2VHs2hMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/favorites/components/SectionContinue.vue?macro=true";
import { default as SectionFavoritesxlSemK2PQGMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/favorites/components/SectionFavorites.vue?macro=true";
import { default as SectionViewedQkDtuetXdkMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/favorites/components/SectionViewed.vue?macro=true";
import { default as continueEF6Dzj7CmAMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/favorites/continue.vue?macro=true";
import { default as indexicbGIQklKIMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/favorites/index.vue?macro=true";
import { default as purchasedyzhPnTr9jaMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/favorites/purchased.vue?macro=true";
import { default as vieweduV9GaMg1oKMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/favorites/viewed.vue?macro=true";
import { default as holder_45policycxwTV21S6iMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/holder-policy.vue?macro=true";
import { default as SectionAdvantagesLzA8TS3UDgMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/home/components/SectionAdvantages.vue?macro=true";
import { default as SectionBannernRX7YJr4OxMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/home/components/SectionBanner.vue?macro=true";
import { default as SectionCollections0cMXpnhHLjMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/home/components/SectionCollections.vue?macro=true";
import { default as SectionContinue4eIKukF57zMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/home/components/SectionContinue.vue?macro=true";
import { default as SectionFilmsfweo8FgWFBMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/home/components/SectionFilms.vue?macro=true";
import { default as SectionGenresSsSES3H6QcMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/home/components/SectionGenres.vue?macro=true";
import { default as SectionHeaderXKRIUP95crMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/home/components/SectionHeader.vue?macro=true";
import { default as SectionPopularyMOkCAuwNSMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/home/components/SectionPopular.vue?macro=true";
import { default as SectionPremiersMnRW19Ie2LMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/home/components/SectionPremiers.vue?macro=true";
import { default as SectionSeries1sqx7Mj7vCMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/home/components/SectionSeries.vue?macro=true";
import { default as SectionSubscriptions7kpJSLj1cpMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/home/components/SectionSubscriptions.vue?macro=true";
import { default as SectionSummarizeL87jwK4EZIMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/home/components/SectionSummarize.vue?macro=true";
import { default as SectionUpcomingvUBWvYin2sMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/home/components/SectionUpcoming.vue?macro=true";
import { default as IndexAoKZmTJxbQMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/home/Index.vue?macro=true";
import { default as _91slug_93V4OiZf2E6MMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/install/[slug].vue?macro=true";
import { default as InfoInstallkWJcrJwZVAMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/install/components/InfoInstall.vue?macro=true";
import { default as TitleInstall5QVly1DFmOMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/install/components/TitleInstall.vue?macro=true";
import { default as typesJUCkWUcplkMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/install/components/types.ts?macro=true";
import { default as useInstall1DPj2RvmskMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/install/components/useInstall.ts?macro=true";
import { default as filmcrew1LadfHIM1FMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/movie/[slug]/filmcrew.vue?macro=true";
import { default as indexXh9XAsPtJTMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/movie/[slug]/index.vue?macro=true";
import { default as MovieCollections53uMcRUOsvMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/movie/components/MovieCollections.vue?macro=true";
import { default as MovieCrewe5xt4yvnKjMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/movie/components/MovieCrew.vue?macro=true";
import { default as MovieDescriptionZbI71TVvlUMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/movie/components/MovieDescription.vue?macro=true";
import { default as MovieHeaderDPB7AnjRNUMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/movie/components/MovieHeader.vue?macro=true";
import { default as MovieHeaderNoticeute1aKOHU5Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/movie/components/MovieHeaderNotice.vue?macro=true";
import { default as MovieHeaderSkeleton48aecJiNSpMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/movie/components/MovieHeaderSkeleton.vue?macro=true";
import { default as MoviePlayButtontZyuCME4iSMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/movie/components/MoviePlayButton.vue?macro=true";
import { default as MovieRelatedzB2ksWuZAcMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/movie/components/MovieRelated.vue?macro=true";
import { default as MovieSeriesvPXcDzk8NuMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/movie/components/MovieSeries.vue?macro=true";
import { default as MovieTrailersWPffPfjB50Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/movie/components/MovieTrailers.vue?macro=true";
import { default as MovieWatchOptions7q88xAsE14Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/movie/components/MovieWatchOptions.vue?macro=true";
import { default as _91slug_93hgxZ7IayfYMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/news/[slug].vue?macro=true";
import { default as indexklQEAvekQCMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/news/index.vue?macro=true";
import { default as novelty_45films7XytnM2ulIMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/novelty-films.vue?macro=true";
import { default as novelty_45seriesGABjfqEoSRMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/novelty-series.vue?macro=true";
import { default as policy_45agreement3HtpaKnZ6hMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/policy-agreement.vue?macro=true";
import { default as policy_45copyrightjRhRMxtwHAMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/policy-copyright.vue?macro=true";
import { default as policy_45holderdd8asCPxHFMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/policy-holder.vue?macro=true";
import { default as policy_45privatevz0k0sTlaYMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/policy-private.vue?macro=true";
import { default as policy_45userFWqcBHK6JNMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/policy-user.vue?macro=true";
import { default as premiersdEg7Qxi3BQMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/premiers.vue?macro=true";
import { default as private_45policy5PTQomBKyxMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/private-policy.vue?macro=true";
import { default as indexI8VSOgty7iMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/profile/bonuses/index.vue?macro=true";
import { default as VChangeRecurrentCardModalc6KDRpAJKwMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/profile/components/modal/VChangeRecurrentCardModal.vue?macro=true";
import { default as VEditProfilenqkMfMmvk9Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/profile/components/modal/VEditProfile.vue?macro=true";
import { default as VRejectSubscribeModaljg481jIhObMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/profile/components/modal/VRejectSubscribeModal.vue?macro=true";
import { default as VRemoveUserData5aBuuDKA2dMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/profile/components/modal/VRemoveUserData.vue?macro=true";
import { default as VResumeSubscriptionSuccessModalRYuhLEvhkTMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/profile/components/modal/VResumeSubscriptionSuccessModal.vue?macro=true";
import { default as PersonalReferrer7h7VaYBiQkMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/profile/components/PersonalReferrer.vue?macro=true";
import { default as validationk9tUUWpY9mMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/profile/components/validation.ts?macro=true";
import { default as VBonusHistorynjQ2djRHiHMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/profile/components/VBonusHistory.vue?macro=true";
import { default as VNotificationCardlR2fUJ3oK3Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/profile/components/VNotificationCard.vue?macro=true";
import { default as VNotificationsSettingsXjGxZO1eu7Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/profile/components/VNotificationsSettings.vue?macro=true";
import { default as VPaymentsz3QAs5BmldMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/profile/components/VPayments.vue?macro=true";
import { default as VProfileTabsIf5vqnyrf5Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/profile/components/VProfileTabs.vue?macro=true";
import { default as VReferralProgramQS2VGTAj1bMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/profile/components/VReferralProgram.vue?macro=true";
import { default as VSubscriptionsY40wILTPDSMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/profile/components/VSubscriptions.vue?macro=true";
import { default as indexXDpXfKbunMMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/profile/devices/index.vue?macro=true";
import { default as indexbBJXbPJ7BqMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/profile/index.vue?macro=true";
import { default as indexyWiKqcEI03Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/profile/notifications/index.vue?macro=true";
import { default as index9o0Lm5gZTVMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/profile/payments/index.vue?macro=true";
import { default as indexODCPn8WbsvMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/profile/settings/index.vue?macro=true";
import { default as indexWTq9brX64GMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/profile/user/index.vue?macro=true";
import { default as result9UdsGvuGlNMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/result.vue?macro=true";
import { default as _91slug_933ShXAV7iKQMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/similar/[slug].vue?macro=true";
import { default as indexYeduxQ8gA0Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/sports/channels/index.vue?macro=true";
import { default as index6MXm8e4ayaMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/sports/index.vue?macro=true";
import { default as SectionAdsh3NjV7TVT2Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/subscriptions/components/SectionAds.vue?macro=true";
import { default as SectionContenticzh5xu8uUMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/subscriptions/components/SectionContent.vue?macro=true";
import { default as SectionDeviceszFgj5sldeIMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/subscriptions/components/SectionDevices.vue?macro=true";
import { default as SectionDownload9s70C4kW7iMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/subscriptions/components/SectionDownload.vue?macro=true";
import { default as SectionHeadergZ9w4PCGViMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/subscriptions/components/SectionHeader.vue?macro=true";
import { default as SectionSubscriptionsbHmcvlbo4DMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/subscriptions/components/SectionSubscriptions.vue?macro=true";
import { default as SectionVoiceoverold73TyWQVMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/subscriptions/components/SectionVoiceover.vue?macro=true";
import { default as indexaeeFjTTPswMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/subscriptions/index.vue?macro=true";
import { default as termsP5EqRFPgTlMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/terms.vue?macro=true";
import { default as upcomingVUK45HzG1NMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/upcoming.vue?macro=true";
export default [
  {
    name: "browse-category",
    path: "/browse/:category()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/browse/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "browse-components",
    path: "/browse/components",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/browse/components/index.ts").then(m => m.default || m)
  },
  {
    name: "browse-components-PageFilters",
    path: "/browse/components/PageFilters",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/browse/components/PageFilters.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionCategories",
    path: "/browse/components/SectionCategories",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/browse/components/SectionCategories.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionCollectionList",
    path: "/browse/components/SectionCollectionList",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/browse/components/SectionCollectionList.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionFilters",
    path: "/browse/components/SectionFilters",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/browse/components/SectionFilters.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionGenres",
    path: "/browse/components/SectionGenres",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/browse/components/SectionGenres.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionMovies",
    path: "/browse/components/SectionMovies",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/browse/components/SectionMovies.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionPopular",
    path: "/browse/components/SectionPopular",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/browse/components/SectionPopular.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionSeries",
    path: "/browse/components/SectionSeries",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/browse/components/SectionSeries.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SkeletonFilters",
    path: "/browse/components/SkeletonFilters",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/browse/components/SkeletonFilters.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SkeletonGenres",
    path: "/browse/components/SkeletonGenres",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/browse/components/SkeletonGenres.vue").then(m => m.default || m)
  },
  {
    name: "browse-genre-slug",
    path: "/browse/genre/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/browse/genre/[slug].vue").then(m => m.default || m)
  },
  {
    name: "browse",
    path: "/browse",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/browse/index.vue").then(m => m.default || m)
  },
  {
    name: "collections-slug",
    path: "/collections/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/collections/[slug].vue").then(m => m.default || m)
  },
  {
    name: "collections",
    path: "/collections",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/collections/index.vue").then(m => m.default || m)
  },
  {
    name: "copyright",
    path: "/copyright",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/copyright.vue").then(m => m.default || m)
  },
  {
    name: "crew-id",
    path: "/crew/:id()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/crew/[id].vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "favorites-all",
    path: "/favorites/all",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/favorites/all.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionBought",
    path: "/favorites/components/SectionBought",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/favorites/components/SectionBought.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionContinue",
    path: "/favorites/components/SectionContinue",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/favorites/components/SectionContinue.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionFavorites",
    path: "/favorites/components/SectionFavorites",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/favorites/components/SectionFavorites.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionViewed",
    path: "/favorites/components/SectionViewed",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/favorites/components/SectionViewed.vue").then(m => m.default || m)
  },
  {
    name: "favorites-continue",
    path: "/favorites/continue",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/favorites/continue.vue").then(m => m.default || m)
  },
  {
    name: "favorites",
    path: "/favorites",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: "favorites-purchased",
    path: "/favorites/purchased",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/favorites/purchased.vue").then(m => m.default || m)
  },
  {
    name: "favorites-viewed",
    path: "/favorites/viewed",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/favorites/viewed.vue").then(m => m.default || m)
  },
  {
    name: "holder-policy",
    path: "/holder-policy",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/holder-policy.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionAdvantages",
    path: "/home/components/SectionAdvantages",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/home/components/SectionAdvantages.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionBanner",
    path: "/home/components/SectionBanner",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/home/components/SectionBanner.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionCollections",
    path: "/home/components/SectionCollections",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/home/components/SectionCollections.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionContinue",
    path: "/home/components/SectionContinue",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/home/components/SectionContinue.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionFilms",
    path: "/home/components/SectionFilms",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/home/components/SectionFilms.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionGenres",
    path: "/home/components/SectionGenres",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/home/components/SectionGenres.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionHeader",
    path: "/home/components/SectionHeader",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/home/components/SectionHeader.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionPopular",
    path: "/home/components/SectionPopular",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/home/components/SectionPopular.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionPremiers",
    path: "/home/components/SectionPremiers",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/home/components/SectionPremiers.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionSeries",
    path: "/home/components/SectionSeries",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/home/components/SectionSeries.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionSubscriptions",
    path: "/home/components/SectionSubscriptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/home/components/SectionSubscriptions.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionSummarize",
    path: "/home/components/SectionSummarize",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/home/components/SectionSummarize.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionUpcoming",
    path: "/home/components/SectionUpcoming",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/home/components/SectionUpcoming.vue").then(m => m.default || m)
  },
  {
    name: "home-Index",
    path: "/home/Index",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/home/Index.vue").then(m => m.default || m)
  },
  {
    name: "install-slug",
    path: "/install/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/install/[slug].vue").then(m => m.default || m)
  },
  {
    name: "install-components-InfoInstall",
    path: "/install/components/InfoInstall",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/install/components/InfoInstall.vue").then(m => m.default || m)
  },
  {
    name: "install-components-TitleInstall",
    path: "/install/components/TitleInstall",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/install/components/TitleInstall.vue").then(m => m.default || m)
  },
  {
    name: "install-components-types",
    path: "/install/components/types",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/install/components/types.ts").then(m => m.default || m)
  },
  {
    name: "install-components-useInstall",
    path: "/install/components/useInstall",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/install/components/useInstall.ts").then(m => m.default || m)
  },
  {
    name: "movie-slug-filmcrew",
    path: "/movie/:slug()/filmcrew",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/movie/[slug]/filmcrew.vue").then(m => m.default || m)
  },
  {
    name: "movie-slug",
    path: "/movie/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/movie/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieCollections",
    path: "/movie/components/MovieCollections",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/movie/components/MovieCollections.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieCrew",
    path: "/movie/components/MovieCrew",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/movie/components/MovieCrew.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieDescription",
    path: "/movie/components/MovieDescription",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/movie/components/MovieDescription.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieHeader",
    path: "/movie/components/MovieHeader",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/movie/components/MovieHeader.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieHeaderNotice",
    path: "/movie/components/MovieHeaderNotice",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/movie/components/MovieHeaderNotice.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieHeaderSkeleton",
    path: "/movie/components/MovieHeaderSkeleton",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/movie/components/MovieHeaderSkeleton.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MoviePlayButton",
    path: "/movie/components/MoviePlayButton",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/movie/components/MoviePlayButton.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieRelated",
    path: "/movie/components/MovieRelated",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/movie/components/MovieRelated.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieSeries",
    path: "/movie/components/MovieSeries",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/movie/components/MovieSeries.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieTrailers",
    path: "/movie/components/MovieTrailers",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/movie/components/MovieTrailers.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieWatchOptions",
    path: "/movie/components/MovieWatchOptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/movie/components/MovieWatchOptions.vue").then(m => m.default || m)
  },
  {
    name: "news-slug",
    path: "/news/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "novelty-films",
    path: "/novelty-films",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/novelty-films.vue").then(m => m.default || m)
  },
  {
    name: "novelty-series",
    path: "/novelty-series",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/novelty-series.vue").then(m => m.default || m)
  },
  {
    name: "policy-agreement",
    path: "/policy-agreement",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/policy-agreement.vue").then(m => m.default || m)
  },
  {
    name: "policy-copyright",
    path: "/policy-copyright",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/policy-copyright.vue").then(m => m.default || m)
  },
  {
    name: "policy-holder",
    path: "/policy-holder",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/policy-holder.vue").then(m => m.default || m)
  },
  {
    name: "policy-private",
    path: "/policy-private",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/policy-private.vue").then(m => m.default || m)
  },
  {
    name: "policy-user",
    path: "/policy-user",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/policy-user.vue").then(m => m.default || m)
  },
  {
    name: "premiers",
    path: "/premiers",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/premiers.vue").then(m => m.default || m)
  },
  {
    name: "private-policy",
    path: "/private-policy",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/private-policy.vue").then(m => m.default || m)
  },
  {
    name: "profile-bonuses",
    path: "/profile/bonuses",
    meta: indexI8VSOgty7iMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/profile/bonuses/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VChangeRecurrentCardModal",
    path: "/profile/components/modal/VChangeRecurrentCardModal",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/profile/components/modal/VChangeRecurrentCardModal.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VEditProfile",
    path: "/profile/components/modal/VEditProfile",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/profile/components/modal/VEditProfile.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VRejectSubscribeModal",
    path: "/profile/components/modal/VRejectSubscribeModal",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/profile/components/modal/VRejectSubscribeModal.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VRemoveUserData",
    path: "/profile/components/modal/VRemoveUserData",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/profile/components/modal/VRemoveUserData.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VResumeSubscriptionSuccessModal",
    path: "/profile/components/modal/VResumeSubscriptionSuccessModal",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/profile/components/modal/VResumeSubscriptionSuccessModal.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-PersonalReferrer",
    path: "/profile/components/PersonalReferrer",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/profile/components/PersonalReferrer.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-validation",
    path: "/profile/components/validation",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/profile/components/validation.ts").then(m => m.default || m)
  },
  {
    name: "profile-components-VBonusHistory",
    path: "/profile/components/VBonusHistory",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/profile/components/VBonusHistory.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VNotificationCard",
    path: "/profile/components/VNotificationCard",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/profile/components/VNotificationCard.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VNotificationsSettings",
    path: "/profile/components/VNotificationsSettings",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/profile/components/VNotificationsSettings.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VPayments",
    path: "/profile/components/VPayments",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/profile/components/VPayments.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VProfileTabs",
    path: "/profile/components/VProfileTabs",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/profile/components/VProfileTabs.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VReferralProgram",
    path: "/profile/components/VReferralProgram",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/profile/components/VReferralProgram.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VSubscriptions",
    path: "/profile/components/VSubscriptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/profile/components/VSubscriptions.vue").then(m => m.default || m)
  },
  {
    name: "profile-devices",
    path: "/profile/devices",
    meta: indexXDpXfKbunMMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/profile/devices/index.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexbBJXbPJ7BqMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-notifications",
    path: "/profile/notifications",
    meta: indexyWiKqcEI03Meta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/profile/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-payments",
    path: "/profile/payments",
    meta: index9o0Lm5gZTVMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/profile/payments/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-settings",
    path: "/profile/settings",
    meta: indexODCPn8WbsvMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/profile/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-user",
    path: "/profile/user",
    meta: indexWTq9brX64GMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/profile/user/index.vue").then(m => m.default || m)
  },
  {
    name: "result",
    path: "/result",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/result.vue").then(m => m.default || m)
  },
  {
    name: "similar-slug",
    path: "/similar/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/similar/[slug].vue").then(m => m.default || m)
  },
  {
    name: "sports-channels",
    path: "/sports/channels",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/sports/channels/index.vue").then(m => m.default || m)
  },
  {
    name: "sports",
    path: "/sports",
    meta: index6MXm8e4ayaMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/sports/index.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionAds",
    path: "/subscriptions/components/SectionAds",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/subscriptions/components/SectionAds.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionContent",
    path: "/subscriptions/components/SectionContent",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/subscriptions/components/SectionContent.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionDevices",
    path: "/subscriptions/components/SectionDevices",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/subscriptions/components/SectionDevices.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionDownload",
    path: "/subscriptions/components/SectionDownload",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/subscriptions/components/SectionDownload.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionHeader",
    path: "/subscriptions/components/SectionHeader",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/subscriptions/components/SectionHeader.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionSubscriptions",
    path: "/subscriptions/components/SectionSubscriptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/subscriptions/components/SectionSubscriptions.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionVoiceover",
    path: "/subscriptions/components/SectionVoiceover",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/subscriptions/components/SectionVoiceover.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions",
    path: "/subscriptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/subscriptions/index.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "upcoming",
    path: "/upcoming",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-353931/src/pages/upcoming.vue").then(m => m.default || m)
  }
]